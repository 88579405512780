import React, {Component} from 'react';

class Blog extends Component {
    render() {
        return (
            <div>
                <div className="container mx-auto px-4 py-8 bg-neutral-700 rounded-3xl space-y-8 border-4 border-neutral-600">

                    <div className="mb-4">
                        <h2 className="text-4xl font-bold text-cyan-400 text-center drop-shadow-md">Neuste
                            Blogbeiträge</h2>
                        <p className="text-neutral-400 text-center font-bold">Bleib mit unseren Blogs auf dem
                            Laufenden!</p>
                    </div>

                    <div className="px-4 lg:px-24 justify-center grid gap-4 sm:grid-cols-4 lg:grid-cols-3">

                        <a href="https://www.BlackNinja.Live/blog-3"
                           className="bg-neutral-800 rounded-3xl mx-6 drop-shadow-lg inline-block cursor-pointer transition ease-in-out hover:scale-110">
                            <div className="p-5">
                                <img
                                    src="https://i.imgur.com/Gac7ZUz.png"
                                    alt="jar.webp"
                                    className="w-80 h-50 mr-auto ml-auto object-cover rounded-3xl max-h-96 drop-shadow-md border-4 border-cyan-400"/>
                            </div>
                            <div className="p-3">
                                <p className="text-2xl text-cyan-400 text-center font-bold ">Lobby JumpAndRun #3</p>
                                <p className="text-lg text-white text-center ">21. August 2024</p>
                            </div>
                        </a>

                        <a href="https://www.BlackNinja.Live/blog-2"
                           className="bg-neutral-800 rounded-3xl mx-6 drop-shadow-lg inline-block cursor-pointer transition ease-in-out hover:scale-110">
                            <div className="p-5">
                                <img
                                    src="https://i.imgur.com/Gac7ZUz.png"
                                    alt="lobby.webp"
                                    className="w-80 h-50 mr-auto ml-auto object-cover rounded-3xl max-h-96 drop-shadow-md border-4 border-cyan-400"/>
                            </div>
                            <div className="p-3">
                                <p className="text-2xl text-cyan-400 text-center font-bold ">Neu Eröffnung #2</p>
                                <p className="text-lg text-white text-center ">29. März 2024</p>
                            </div>
                        </a>

                        <a href="https://www.BlackNinja.Live/blog-1"
                           className="bg-neutral-800 rounded-3xl mx-6 drop-shadow-lg inline-block cursor-pointer transition ease-in-out hover:scale-110">
                            <div className="p-5">
                                <img
                                    src="https://i.imgur.com/VqE70MB.png"
                                    alt="blog1.webp"
                                    className="w-80 h-50 mr-auto ml-auto object-cover rounded-3xl max-h-96 drop-shadow-md border-4 border-cyan-400"/>
                            </div>
                            <div className="p-3">
                                <p className="text-2xl text-cyan-400 text-center font-bold ">Frohe Weihnachten! #1</p>
                                <p className="text-lg text-white text-center ">24. Dezember 2023</p>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        );
    }
}

export default Blog;